body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row{
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}




  * {
    font-family: 'Alexandria', sans-serif;
    font-size:  small; /* Adjusts the font size to be smaller */
    font-weight: small; /* Sets the font weight to normal instead of bold */
  }
  
