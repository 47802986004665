.navbar{
   background-color:  #08391d;
}
.prize_user_links{
    width: 100%;
     display: flex;
     justify-content: end;
}
.nav-link {
    color: rgb(246, 208, 164);
    font-size: 14px;
    font-weight: 500;
    margin-left: 4px;
}
.nav-link:hover{
    color: white !important;
}

footer{
    background-color:  #08391d;
    color: white;
    font-size: 14px;
    font-weight: 700;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.white-toggler {
    background-color: white;
    border: 1px solid white; /* Optional: Add border for better visibility */
    color: black; /* Optional: Set text color */
  }
  .prize_user_logo{
    width: 130px;
  }



  .userConfirm{
    background-color: rgb(209, 231, 221);
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}
.user_icon_info{
    background-color: #08391d;
    width: 20px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.user_support{
    color: #08391d;
    font-weight: 500;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: rgb(231, 162, 64) !important;
    text-decoration: none;
    border-bottom: 2px solid rgb(231, 162, 64); /* Adjust the thickness as needed */
    padding-bottom: 5px; /* Adjust the space between text and line as needed */
}

  @media only screen and (max-width: 992px) {
    .prize_user_links{
         justify-content: center;
    }
    .prize_user_logo{
        width: 80px;
      }
    
  }


  /* notifications */

  /* MyHeader.css */

/* Styles for the notification dropdown */
/* MyHeader.css */

/* Styles for the notification dropdown */
.notification-dropdown {
    position: relative;
  }
  
  .notification-dropdown .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .notification-dropdown .badge {
    position: absolute;
    top: -5px;
    right: -5px;
  }
  
  .notification-dropdown .dropdown-menu {
    max-height: 300px; /* Set a max height for the dropdown menu */
    overflow-y: auto; /* Enable vertical scrolling if needed */
    width: 350px; /* Set the desired width for the dropdown */
    border: none; /* Remove border */
    border-radius: 0; /* Remove border-radius */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    margin-top: 0; /* Remove margin-top */
    background-color: #fff; /* Background color for the dropdown */
    padding: 0px;
    padding-bottom: 10px;
    border-radius: 0 5px 5px 0;
  }
  
  .notification-dropdown .notification-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    border: none;
  }
  
  .notification-dropdown .notification-action {
    font-weight: bold;
  }
  
  .notification-dropdown .notification-time {
    font-size: 12px;
    color: #777;
  }
  
  .notification-dropdown .notification-link {
    text-align: center;
    font-weight: bold;
    border-top: 1px solid #ddd;
    padding-top: 5px;
  }
  
  /* Add background color on hover */
  .notification-dropdown .dropdown-menu .notification-item:hover {
    background-color: rgb(0, 166, 147);
    color: white;
    /* Green background color on hover */
  }
  
  
.dropdown-item{
    color: #08391d;
}
.user_notification_link{
    text-decoration: none;
    color: #08391d;
}
@media only screen and (max-width: 500px) {
    .dropdown-menu {
        width: 220px;
    }
}
  /* end notifications */